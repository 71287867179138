import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    type: string
    emailQue: any
    execute: boolean
}

const initialState = {
    type: '',
    emailQue: []
} as IModalState

const slice = createSlice({
    name: 'mail-state-store',
    initialState,
    reducers: {
        addToQue(state: any, {payload}: any) {
            state.emailQue = payload
        },
        setType(state: any, {payload}: any) {
            state.type = payload
        },
        clearQue(state: any) {
            state.emailQue = []
        },
        setExecute(state: any, {payload}: any) {
            state.execute = payload
        }
    },
})

export const {addToQue, clearQue, setType, setExecute} = slice.actions
export default slice.reducer
