import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connectAppBridge } from "../../../store/reducers/app-bridge/app-bridge.store";

const useAppBridge = () => {
    const dispatch = useDispatch()
    useEffect(() => {

        window.addEventListener("message", function (e) {
            dispatch(connectAppBridge(e.data))
        });
    }, [])
}

export default useAppBridge
