import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {GRAPH_BASE} from "../const/app.const";

export const msGraphApi = createApi({
    reducerPath: 'msGraphApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GRAPH_BASE,
    }),
    tagTypes: ['messages'],
    endpoints: builder => ({
        messages: builder.query({
            query: () => ({
                url: 'v1.0/me/messages',
                method: "GET",
            }),
            providesTags: ['messages']
        }),
        createEmail: builder.mutation<any, any>({
            query: ({message, accessToken}: any) => ({
                url: '/v1.0/me/messages',
                method: 'POST',
                body: message,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }),
        }),
        updateEmail: builder.mutation<any, any>({
            query: ({id, message, accessToken}: any) => ({
                url: `/v1.0/me/messages/${id}`,
                method: 'PATCH',
                body: message,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }),
        }),
        sendEmail: builder.mutation<any, any>({
            query: ({message, accessToken}: any) => ({
                url: `/v1.0/me/messages/${message.id}/send`,
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }),
        }),
        addAttachment: builder.mutation<any, any>({
            query: ({id, body, accessToken}: any) => ({
                url: `/v1.0/me/messages/${id}/attachments`,
                method: 'POST',
                body: body,
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": 'application/json'
                }
            }),
        }),
        execPut: builder.mutation<any, any>({
            query: ({url, body, accessToken}: any) => ({
                url: `/v1.0/me/${url}`,
                method: 'PUT',
                body: body,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }),
        }),
        execGet: builder.query({
            query: ({url, accessToken}: any) => ({
                url: `v1.0/me/${url}`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
            })
        }),

    })
})

// export const {useMessagesQuery} = msGraphApi
export default msGraphApi
