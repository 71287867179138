import _ from "lodash";
import moment from "moment";

export const log = (message: any, arg?: any) => !arg ? console.log(message) : console.log(message, arg)

//You can pass pattern and value as an arguments and return the is Match or not
export const isMatch = (pattern: any, value: any) => {
    return new RegExp(pattern).test(value);
}

//You can pass a string or number value as an argument and return value will be truncated to 2 decimal points without rounding
export const truncate = (value: string | number) => value.toString().match(/^-?\d+(?:\.\d{0,2})?/)

export const isArray = (list: any): boolean => Array.isArray(list)

/**
 *
 * @deprecated
 */
export const isEmptyArray = (list: any): boolean => (Array.isArray(list) && list.length == 0)

export const toStringWithSlash = (model: any) => JSON.stringify(model)?.replace(/"/g, '\\"')

//check is json
export const isJSON = (json: string) => {
    try {
        JSON.parse(json);
    } catch (e) {
        return false;
    }
    return true;
}

export const isString = (value: any) => typeof value === 'string' || value instanceof String;

export const isPromise = (promise: any) => typeof promise == 'object' && typeof promise.then == 'function';

export const isFunction = (func: any) => typeof func == 'function';

export const getModelValue = (model: any, path: any) => {
    if (isString(model)) return model
    return _.get(model, path)
}

//get changed keys from object
export const changedKeys = (o1: any, o2: any) => {
    var keys = _.union(_.keys(o1), _.keys(o2));
    return _.filter(keys, function (key) {
        return _.get(o1, key) !== _.get(o2, key);
    });
}

export const getCurrentDateTime = (type: 'time' | 'date') => {
    let current = moment(new Date()).format('YYYY-MM-DD HH:mm')
    let hourString = current.split(" ")[1].split(":")[0]
    let hourInNumber = Number(hourString)
    let minuteString = current.split(" ")[1].split(":")[1]
    let minuteInNumber = Number(minuteString)
    let roundedMinute = (Math.ceil(minuteInNumber / 15) * 15) % 60
    let finalDate;

    if (minuteInNumber > 45) {
        let hourAdjustedDate = (current.replace(`${hourString}:`, hourInNumber < 10 ? `0${hourInNumber + 1}:` : `${hourInNumber + 1}:`))
        finalDate = (hourAdjustedDate.replace(`:${minuteString}`, `:${roundedMinute == 0 ? '00' : String(roundedMinute)}`))
    } else {
        finalDate = (current.replace(`:${minuteString}`, `:${roundedMinute == 0 ? '00' : String(roundedMinute)}`))
    }

    switch (type) {
        case 'date':
            return finalDate.split(' ')[0]
        case 'time':
            return finalDate.split(' ')[1]
        default:
            return finalDate
    }
}


export const currencyFormatter = (value: any, code = null ) => {
    const _value: any = (Math.round(isNaN(value) ? 0 : value * 100) / 100).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    })
    if (code) return `${code} ${_value}`
    return `${_value}`
}

export const generateEndDateTime = (type: 'time' | 'date', startDate: any, startTime: any) => {
    if (startDate) {
        let date = `${startDate} ${startTime}`
        switch (type) {
            case 'date':
                return moment(moment(date).add(30, 'm').toDate()).format('YYYY-MM-DD')
            case 'time':
                return moment(moment(date).add(30, 'm').toDate()).format('HH:mm')
            default:
                return moment(moment(date).add(30, 'm').toDate()).format('YYYY-MM-DD HH:mm')
        }
    }
    return null
}