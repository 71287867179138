import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from "../const/app.const";
import docTypes from "./tag-types";

interface IGetQueryParams {
    method: string,
    fetchCount: number,
    fields: [],
    filters: [],
    searchParams: string
}

export const erpResourceApi = createApi({
    reducerPath: 'resourceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/resource/",
        credentials: 'include'
    }),
    tagTypes: docTypes,
    endpoints: build => ({
        list: build.query<any, any>({
            query: ({
                method,
                fetchCount = 1000000,
                fields = [],
                filters = [],
                searchParams,
            }: IGetQueryParams) => `${method}?fields=${JSON.stringify(fields)}&filters=${filters.length > 0 ? filters : JSON.stringify([])}&limit_page_length=${fetchCount}&${searchParams}`,
            providesTags: ['list'],
            extraOptions: { subscribe: false, forceRefetch: true }
        }),
        get: build.query<any, any>({
            query: ({
                doc,
                name
            }: any) => `${doc}/${name}`,
            providesTags: (result, error, arg) => {
                if (result) {
                    return [{ type: 'doc', id: `${result.data.doctype}-${result.data.name}` }]
                }

                return ['doc']
            }
        }),
        create: build.mutation<any, any>({
            query: ({
                doc,
                payload
            }: any) => ({
                url: `${doc}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['list', 'doc']
        }),
        update: build.mutation<any, any>({
            query: ({
                doc,
                name,
                payload
            }: any) => ({
                url: `${doc}/${name}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['doc', 'list']
        }),
        delete: build.mutation<any, any>({
            query: ({
                doc,
                name,
            }: { doc: string, name: string }) => ({
                url: `${doc}/${name}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['list', 'doc']
        }),
    })
})

export const { useListQuery, useGetQuery, useUpdateMutation, useCreateMutation, useDeleteMutation } = erpResourceApi
export default erpResourceApi
