import {combineReducers} from "redux";

import _uiStateStore from './general-ui';
import _pagesStore from './page';
import _erpStore from './lib/erp-data.store';
import {erpResourceApi} from "../../services/erp-resource-api.service";
import {erpMethodApi} from "../../services/erp-method-api.service";
import _adminPrivilegesStore from "./admin-privileges/admin-privileges.store";
import _appStore from './apps'
import _localStore from './local-store/local.store'
import googleMapApi from "../../services/google-map.service";
import {usersApi} from "../../services/erp-users.service";
import msGraphApi from "../../services/ms-graph.service";
import _mailStore from "./mail/mail.store";
import _formStore from "./form/form.store"
import _appBridgeStore from "./app-bridge/app-bridge.store"

const rootReducer = combineReducers({
    uiStore: _uiStateStore,
    pagesStore: _pagesStore,
    appBridge: _appBridgeStore,
    erp: _erpStore,
    apps: _appStore,
    localStore: _localStore,
    privileges: _adminPrivilegesStore,
    mailStore: _mailStore,
    form: _formStore,
    [erpResourceApi.reducerPath]: erpResourceApi.reducer,
    [erpMethodApi.reducerPath]: erpMethodApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [googleMapApi.reducerPath]: googleMapApi.reducer,
    [msGraphApi.reducerPath]: msGraphApi.reducer
})

export default rootReducer;
