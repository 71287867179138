import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../const/app.const";

interface IQueryArgs {
    url: String;
    report_name: String;
}

export const erpMethodApi = createApi({
    reducerPath: "methodServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/method/",
        credentials: "include",
    }),
    tagTypes: ['exec'],
    endpoints: (build) => ({
        getReportApi: build.query<any, any>({
            query: ({ url, report_name }: IQueryArgs) =>
                url + "?report_name=" + report_name,
        }),
        execMethodApi: build.query<any, any>({
            query: ({ method, body }) => ({
                url: `${method}`,
                method: 'POST',
                body: body
            }),
            providesTags: ['exec']
        }),
        execMutationApi: build.mutation<any, any>({
            query: ({ method, body }) => ({
                url: `${method}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['exec']
        })
    }),
});

export const { useGetReportApiQuery, useExecMethodApiQuery } = erpMethodApi;
