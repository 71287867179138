import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/root.reducer";
import { erpResourceApi } from "../services/erp-resource-api.service";
import { erpMethodApi } from "../services/erp-method-api.service";
import { usersApi } from "../services/erp-users.service";
import msGraphApi from "../services/ms-graph.service";
import {formBuilderMiddleware} from "./middlewares/form-buider/form-builder.middleware";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        // getDefaultMiddleware().concat(usersApi.middleware);
        return getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(erpResourceApi.middleware)
            .concat(erpMethodApi.middleware)
            .concat(usersApi.middleware)
            .concat(msGraphApi.middleware)
            .concat(formBuilderMiddleware)
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootStore = ReturnType<typeof store.getState>;
