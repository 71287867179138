import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const reducerPath = "appBridge"

interface ISetModelPayload {
    store: any
}

const initialState = {
    model: {}
}

const slice = createSlice({
    name: 'appBridge',
    initialState,
    reducers: {
        connectAppBridge(state, { payload }: PayloadAction<ISetModelPayload>) {
            state.model = payload.store
        }
    }
})

export const { connectAppBridge } = slice.actions
export default slice.reducer

export const sendMessage = (model: any) => {
    window.parent.postMessage(model, 'http://localhost:3000');
}