import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    model: {}
}

const slice = createSlice({
    name: 'sales-forecast',
    initialState,
    reducers: {
        setForecastModel(state, {payload}: any) {
            state.model = payload
        },
        setForecastLine(state, {payload}: any) {
            state.model = {...state.model, ...payload}
        }
    },
})

export const {setForecastModel, setForecastLine} = slice.actions
export default slice.reducer
