import './App.css';
import useAppBridge from './app/hooks/app-bridge/useAppBridge';

function App() {
  useAppBridge()

  return (
    <h1>This is a child</h1>
  );
}

export default App;
