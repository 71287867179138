import _uiStateStore from './general-ui.store';
import _alertStateStore from './alert.store';
import _sidebarStore from './internal-layout/sidebar.store'
import _messageStore from './message.store'
import _infoBubble from './info-bubble.store'
import _confirmDialog from './confirm-dialog.store'
import _fileUploadDialog from './file-upload-dialog.store'

import {combineReducers} from "redux";

const reducers = combineReducers({
    ui: _uiStateStore,
    alert: _alertStateStore,
    fileUpload: _fileUploadDialog,
    confirmDialog: _confirmDialog,
    infoBubble: _infoBubble,
    message: _messageStore,
    sidebar: _sidebarStore,
})

export default reducers