import _ from 'lodash';
import { isArray, isFunction, isString, changedKeys } from "../../../utils/utils";

export const formBuilderMiddleware = (store: any) => (next: any) => (action: any) => {
    if (action.type == "form-state-store/setFormModel") {
        let formKey = getFormKey(action.payload.path)
       
        let prevModel = _.get(store.getState().form.model, formKey)
        next(action)
        let nextModel = _.get(store.getState().form.model, formKey)

        let dependency = _.get(store.getState().form.dependency, formKey)

        let changes = changedKeys(prevModel, nextModel)

        let middlewareModel = _.get(store.getState().form.middleware, formKey)

        let middlewareKeys = _.keys(middlewareModel)

        middlewareKeys.forEach((key: string) => {

            let dependencyList = _.get(dependency, key)

            let intersection = _.intersection(dependencyList, changes)
            let cloneAction = _.set(_.cloneDeep(action), ['payload', 'path', 1], key)
           
            let passModel = {
                store: _.get(store.getState().form.model, formKey),
                next: (value: any) => {
                    let action = { ..._.set(cloneAction, ['payload', 'model'], value) }
                    next(action)
                }
            }

            !_.isEmpty(intersection) && isFunction(middlewareModel[key]) && middlewareModel[key](passModel)

        })

    } else {
        let result = next(action)
        return result
    }
}

const getFormKey = (value: any) => {
    if (isString(value)) return value
    else if (isArray(value)) return value[0]
    else throw "The type of the form key is not valid."
}