import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    salesInvoiceLines: null,
    data: {},
    item: {}
}

const slice = createSlice({
    name: 'inventory-analysis-app-slice',
    initialState,
    reducers: {
        setSalesInvoiceLines(state, {payload}: any) {
            state.salesInvoiceLines = payload
        },
        setData(state: any, {payload}: any) {
            state.data[payload.key] = payload.message
        },
        setItem(state: any, {payload}: any) {
            state.item = payload
        }
    },
})

export const {setSalesInvoiceLines, setData, setItem} = slice.actions
export default slice.reducer
