import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import _ from "lodash";

interface ISetModelPayload {
    model: any,
    path: string | any[]
}

interface IValidatePayload {
    model: boolean,
    path: string | any[]
}

interface IRemovePayload {
    path: string,
    formKey: string
}

const initialState = {
    validationTrigger: {},
    model: {},
    validation: {},
    middleware: {},
    dependency: {}
}

const slice = createSlice({
    name: 'form-state-store',
    initialState,
    reducers: {
        setFormModel(state, {payload}: PayloadAction<ISetModelPayload>) {
            state.model = _.set(state.model, payload.path, payload.model)
        },
        setFormValidation(state, {payload}: PayloadAction<ISetModelPayload>) {
            state.validation = _.set(state.validation, payload.path, payload.model)
        },
        setFormMiddleware(state, {payload}: PayloadAction<ISetModelPayload>) {
            state.middleware = _.set(state.middleware, payload.path, payload.model)
        },
        setFielDependency(state, {payload}: PayloadAction<ISetModelPayload>) {
            state.dependency = _.set(state.dependency, payload.path, payload.model)
        },
        setFormValidationLog(state, {payload}: PayloadAction<IValidatePayload>) {
            state.validationTrigger = _.set(state.validationTrigger, payload.path, payload.model)
        },
        removeFormField(state, {payload}: PayloadAction<IRemovePayload>) {
            let model = _.cloneDeep(_.get(state.model, payload.formKey))
            if (_.unset(model, payload.path)) {
                state.model = _.set(state.model, payload.formKey, model)
            }
        }
    },
})

export const {setFormModel, removeFormField, setFormValidation, setFormValidationLog, setFormMiddleware, setFielDependency} = slice.actions
export default slice.reducer
