import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    model: {},
    docInfo: {}
}

const slice = createSlice({
    name: 'po-app-store',
    initialState,
    reducers: {
        setModel(state, {payload}: any) {
            state.model = payload
        },
        setDocInfo(state, {payload}: any) {
            state.docInfo = payload
        },
        setValue(state: any, {payload}: any) {
            state.model.draft_date = payload.value
        },

    },
})

export const {setModel, setValue, setDocInfo} = slice.actions
export default slice.reducer
