import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    isLoading: boolean,
    appConfig: any,
    currentSelectedWorkspace: any,
    workspace: any,
    page: any,
    theme: any,
    roles: [],
    currentUser: null,
    loadingText: null
}

const initialState = {
    isLoading: false,
    appConfig: null,
    currentSelectedWorkspace: null,
    workspace: null,
    page: null,
    theme: 'office',
    roles: [],
    currentUser: null,
    loadingText: null
} as IModalState

const slice = createSlice({
    name: 'general-ui-store',
    initialState,
    reducers: {
        showLoading(state, {payload}: any) {
            state.isLoading = true
            state.loadingText = payload
        },
        hideLoading(state) {
            state.isLoading = false
            state.loadingText = null
        },
        setAppConfig(state, {payload}: any) {
            state.appConfig = payload
        },
        setSelectedWorkspace(state, {payload}: any) {
            state.currentSelectedWorkspace = payload
        },
        setWorkspace(state, {payload}: any) {
            state.workspace = payload
        },
        setPage(state, {payload}: any) {
            state.page = payload
        },
        setTheme(state, {payload}: any) {
            state.theme = payload
        },
        setRoles(state, {payload}: any) {
            state.roles = payload
        },
        setCurrentUser(state, {payload}: any) {
            state.currentUser = payload
        }
    },
})

export const {
    setCurrentUser,
    showLoading,
    hideLoading,
    setSelectedWorkspace,
    setAppConfig,
    setWorkspace,
    setPage,
    setTheme,
    setRoles
} = slice.actions
export default slice.reducer
