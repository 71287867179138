import _googleMapStore from './maps/google-map.store'
import _adminPanelStore from './admin/panel.store'
import _inventoryAnalysisStore from './inventory-analysis/inventory-analysis.store'
import _salesForecast from './sales-forecast/sales-forecast.store'
import _poStore from './purchase-order/app-po.store'
import _emailStore from './email/email.store'

import {combineReducers} from "redux";

const reducers = combineReducers({
    map: _googleMapStore,
    inventoryAnalysis: _inventoryAnalysisStore,
    salesForecast: _salesForecast,
    adminPanel: _adminPanelStore,
    po: _poStore,
    email: _emailStore,
})

export default reducers
