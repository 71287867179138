import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GOOGLE_MAP_NON_REFERER_KEY } from '../const/map.const'


const googleMapApi = createApi({
    reducerPath: 'mapApi',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://maps.googleapis.com/maps/api/",
    }),
    endpoints: builder => ({
        geocode: builder.query({
            query: ({ address = '' }) => ({
                url: `geocode/json?address=${address}&key=${GOOGLE_MAP_NON_REFERER_KEY}`,
                method: "GET",
            })
        })
    })
})

export const { useGeocodeQuery } = googleMapApi
export default googleMapApi